import axios from "axios";

export default {
  // Start:: Get Countries
  async getCountries(context) {
    try {
      let res = await axios({
        method: "GET",
        url: `countries_helper`,
      });
      // console.log("COUNTRIES =>", res.data.data);
      context.commit("setCountries", res.data.data.map(item => {
        return {
          id: item.id,
          name: item.title,
        }
      }));
    } catch (error) {
      console.log(error.response.data.message);
    }
  },
  // End:: Get Countries

  // Start:: Get Cities
  async getCitiesByCountryId(context, payload) {
    try {
      let res = await axios({
        method: "GET",
        url: `cities_by_country_id/${payload}`,
      });
      // console.log("CITIES =>", res.data.data);
      context.commit("setCitiesByCountryId", res.data.data.map(item => {
        return {
          id: item.id,
          name: item.title,
        }
      }));
    } catch (error) {
      console.log(error.response.data.message);
    }
  },
  // End:: Get Cities

  // Start:: Get Books
  async getBooks(context) {
    try {
      let res = await axios({
        method: "GET",
        url: `books_list`,
      });
      // console.log("Books =>", res.data.data);
      context.commit("setBooks", res.data.data.map(item => {
        return {
          id: item.id,
          name: item.title,
        }
      }));
    } catch (error) {
      console.log(error.response.data.message);
    }
  },
  // End:: Get Books

  // Start:: Get Sections
  async getSectionsByBookId(context, payload) {
    try {
      let res = await axios({
        method: "GET",
        url: `sections_by_book_id/${payload}`,
      });

      context.commit("setSectionsByBookId", res.data.data.map(item => {
        return {
          id: item.id,
          name: item.title,
        }
      }));
    } catch (error) {
      console.log(error.response.data.message);
    }
  },
  // End:: Get Sections

  // Start:: Get Chapters
  async getChaptersBySectionId(context, payload) {
    try {
      let res = await axios({
        method: "GET",
        url: `chapters_by_section_id/${payload}`,
      });
      // console.log("Chapters By Section Id =>", res.data.data);
      context.commit("setChaptersBySectionId", res.data.data.map(item => {
        return {
          id: item.id,
          name: item.title,
        }
      }));
    } catch (error) {
      console.log(error.response.data.message);
    }
  },
  // End:: Get Chapters

    // Start:: Get Chapters
    async getLessonsByChapterId(context, payload) {
      try {
        let res = await axios({
          method: "GET",
          url: `lessons_by_chapter_id/${payload}`,
        });
        context.commit("setLessonsByChapterId", res.data.data.map(item => {
          return {
            id: item.id,
            name: item.title,
          }
        }));
      } catch (error) {
        console.log(error.response.data.message);
      }
    },
    // End:: Get Chapters
  

  // Start:: Get Roles
  async getRoles(context) {
    try {
      let res = await axios({
        method: "GET",
        url: `main/roles`,
      });
      // console.log("ROLES =>", res.data.body.roles_and_permissions);
      context.commit("setRoles", res.data.data.roles_and_permissions.map(item => {
        return {
          id: item.id,
          name: item.name,
        }
      }));
    } catch (error) {
      console.log(error.response.data.message);
    }
  },
  // End:: Get Roles
};
