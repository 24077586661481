import ApiGetsMutations from "./mutations";
import ApiGetsActions from "./actions.js";
import ApiGetsGetters from "./getters.js";

export default {
  namespaced: true,
  state: {
    // Start:: Countries
    countries: [],
    // End:: Countries

    // Start:: Cities
    citiesByCountryId: [],
    // End:: Cities

    // Start:: Books
    books: [],
    // End:: Books

    // Start:: Sections
    sectionsByBookId: [],
    // End:: Sections

    // Start:: Chapters
    chaptersBySectionId: [],
    // End:: Chapters

    // Start:: Lessons
    lessonsByChapterId: [],
    // End:: Lessons

    // Start:: Roles
    roles: null,
    // End:: Roles
  },
  mutations: ApiGetsMutations,
  actions: ApiGetsActions,
  getters: ApiGetsGetters,
};
