<template>
  <div class="data_inline_preview_card" :class="{active: itemIsActive }">
    <div class="card_body">
      <h5 class="card_title" @click.stop="$emit('fireCardClick')"> {{cardTitle}} </h5>
      <div class="card_btns">
        <router-link :to="showRoute" class="show_route" v-if="showRoute">
          <i class="fal fa-eye"></i>
        </router-link>
        <router-link :to="editRoute" class="edit_route" v-if="editRoute">
          <i class="fal fa-pen"></i>
        </router-link>
        <button class="delete_btn" @click="$emit('fireDelete')" v-if="canDelete">
          <i class="fal fa-trash"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InlineDataPreviewCard",

  emits: ["fireCardClick", "fireDelete"],

  props: {
    itemIsActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    cardTitle: {
      type: String,
      required: true
    },
    showRoute: {
      required: true
    },
    editRoute: {
      required: true
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.data_inline_preview_card {
  margin-block: 6px;
  padding: 0 15px;
  width: 100%;
  background-color: var(--theme_secondary_bg_clr);
  border-radius: 35px;
  cursor: pointer;
  transition: all 0.3s linear;
  @include simpleShadow;
  
  &:hover,
  &.active {
    background-color: var(--main_theme_clr);
    .card_body {
      .card_title {
        color: var(--white_clr);
      }
      .card_btns {
        .edit_route {
          background-color: var(--white_clr);
          svg, i {
            color: var(--main_theme_clr);
          }
        }
      }
    }
  }

  .card_body {
    @include flexStartAlignment;
    column-gap: 2px;
    .card_title {
      min-width: 73%;
      max-width: 73%;
      margin: 0;
      padding-block: 10px;
      color: var(--theme_text_clr);
      text-align: start;
      font-size: 16px;
      font-family: $semi_bold_font;
      line-height: 1.5;
      word-break: break-all;
    }

    .card_btns {
      @include flexCenterAlignment;
      column-gap: 5px;
      min-width: 23%;
      max-width: 23%;

      .show_route,
      .edit_route,
      .delete_btn {
        width: 26px;
        height: 26px;
        padding: 5px;
        border-radius: 5px;
        @include flexCenterAlignment;
        transition: all 0.3s linear;
        svg, i {
          color: var(--white_clr);
          font-size: 14px;
          transition: all 0.3s linear;
        }
      }
      .show_route {
        background-color: var(--secondary_theme_clr);
      }
      .edit_route {
        background-color: var(--main_theme_clr);
      }
      .delete_btn {
        background-color: var(--mid_red_clr);
      }
    }
  }
}
</style>