<template>
  <div class="auth_wrapper">
    <!-- Start:: Particles -->
    <vue-particles
      color="#249ad5"
      :particleOpacity="0.7"
      :particlesNumber="150"
      shapeType="circle"
      :particleSize="5"
      linesColor="#249ad5"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.3"
      :linesDistance="150"
      :moveSpeed="2"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <!-- End:: Particles -->

    <!-- Start:: Language Switcher Button -->
    <div class="theme_changer_wrapper">
      <v-switch
        class="m-0"
        color="#147CCC"
        inset
        hide-details
        :append-icon="getAppTheme === 'light_theme' ? '☀️' : '🌕'"
        v-model="appTheme"
        value="dark_theme"
        @change="
          changeTheme(
            getAppTheme === 'light_theme'
              ? 'light_theme'
              : 'light_theme'
          )
        "
        @click:append="changeTheme(getAppTheme)"
      />
    </div>

    <!-- <div class="lang_toggler_wrapper">
      <button
        aria-label="lang_toggeler_btn"
        class="lang_toggeler_btn"
        @click="changeAppLanguage('en')"
        v-if="getAppLocale == 'ar'"
      >
        En
      </button>
      <button
        aria-label="lang_toggeler_btn"
        class="lang_toggeler_btn pb-2"
        @click="changeAppLanguage('ar')"
        v-else
      >
        ع
      </button>
    </div> -->
    <!-- End:: Language Switcher Button -->

    <!-- Start:: Auth Router View -->
    <div class="container">
      <div class="row justify-content-around">
        <div class="d-none d-lg-block col-lg-5">
          <img
            src="@/assets/media/illustrations/login.svg"
            class="w-100 h-100"
            width="100"
            height="100"
            alt="login"
            loading="lazy"
          />
        </div>
        <div class="col-lg-5">
          <div class="auth_form_wrapper">
            <!-- Start:: Form Title -->
            <router-link to="/" class="logo_wrapper">
              <img
                src="@/assets/media/logo/logo.svg"
                alt="Logo"
                width="260"
                height="100"
              />
            </router-link>
            <!-- End:: Form Title -->
      
            <transition name="fadeInUp" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
      </div>
    </div>
    <!-- End:: Auth Router View -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Authentication",

  computed: {
    // Start:: Vuex Get App Lang
    ...mapGetters({
      getAppLocale: "AppLangModule/getAppLocale",
      getAppTheme: "AppThemeModule/getAppTheme",
    }),
    // End:: Vuex Get App Lang
  },

  data() {
    return {
      // Start:: App Theme Data "For V-switch"
      appTheme: localStorage.getItem("urc_dashboard_theme"),
      // End:: App Theme Data "For V-switch"
    }
  },

  methods: {
    // Start:: Vuex Set App Lang
    ...mapActions({
      changeAppLanguage: "AppLangModule/changeAppLanguage",
      changeAppTheme: "AppThemeModule/changeAppTheme",
    }),
    // End:: Vuex Set App Lang

    // Start:: Handling App Theme
    changeTheme(newTheme) {
      this.appTheme = newTheme;
      setTimeout(() => {
        this.changeAppTheme();
      }, 100);
    },
    // End:: Handling App Theme
  },
};
</script>
